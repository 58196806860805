@import url("./shared/css/animate.css");
@import url("./shared/css/bootstrap.min.css");
@import url("./shared/css/cubeportfolio.min.css");
@import url("./shared/css/font-awesome.min.css");
@import url("./shared/css/magnific-popup.css");
@import url("./shared/css/main.css");
@import url("./shared/css/owl.carousel.css");
@import url("./shared/css/responsive.css");

.carousel-indicators {
  display: none;
}
